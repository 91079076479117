html, body, #root {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
}

#page-content {
  flex: 1 0 auto
}

#header, #footer {
  flex-shrink: 0;
}

.fa-map-marker-alt path {
  stroke: black;
  stroke-width: 20;
}