// change the theme
$theme-colors: (
  "far": #11693f
);

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";

// Hack for the FortnightlyComparison header page title.
// Reduce the page title font size below the lg breakpoint
.page-title {
  font-size: 1.5em;
}
@include media-breakpoint-up(lg) {
  .page-title {
    font-size: 1.6em;
  }
}
